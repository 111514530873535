import './society.scss';
import s1 from './societyimg/societe1.png'
import s2 from './societyimg/society2.png'
import s3 from './societyimg/society3.png'
import s4 from './societyimg/society4.png'
import s5 from './societyimg/society5.png'
export default function Society() {
return(

<div class="slider">
	<div class="slide-track">
		<div class="slide">
			<img src={s1} height="100" width="250" alt=""className='s1' />
		</div>
		<div class="slide">
			<img src={s2} height="100" width="250" alt="" className='s2' />
		</div>
		<div class="slide">
			<img src={s3} height="100" width="250" alt="" className='s3'  />
		</div>
		<div class="slide">
			<img src={s4} height="100" width="250" alt="" className='s4' />
		</div>
		<div class="slide">
			<img src={s5} height="100" width="250" alt="" className='s5' />
		</div>
        
	
	</div>
</div>

)

}
