import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formlangues() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn.generationvoyage.fr/2020/05/shutterstock_424744765-630x420.jpg'></img>
  </div>
  <p  className='myparagtime' >Anglais</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.mjcescalquens.fr/wp-content/uploads/2018/07/espagnol-pixabay.comfrespagne-drapeau-h%C3%A9raldique-red-2906824.png'></img>
  </div>
  <p  className='myparagtime' >Espagnol</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.larousse.fr/encyclopedie/data/images/1009454-Drapeau_de_lAllemagne.jpg'></img>
  </div>
  <p  className='myparagtime' >Allemand</p>
</div>

</div>   


  


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.code-couleur.com/images/main/header2020/drapeau-italie.jpg'></img>
  </div>
  <p  className='myparagtime' >Italien</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.advantour.com/russia/images/symbolics/russia-flag.jpg'></img>
  </div>
  <p  className='myparagtime' >Russe</p>
</div>

</div>   

<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://img.freepik.com/photos-premium/drapeau-chine-espace-copie_46250-2533.jpg'></img>
  </div>
  <p  className='myparagtime' >Chinois</p>
</div>

</div> 

  </div>
</section>
<Bizzard/>

</div>
  )}