import React, { useState } from 'react';
import './top.css';
import { FaBars } from 'react-icons/fa';
import log from './logoblanc.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faInfoCircle, faGears, faUsers, faAngleDoubleUp, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';

export default function Top() {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getActiveItem = (path) => {
    if (path === '/accueil') return 'accueil';
    if (path === '/apropos') return 'apropos';
    if (path === '/nosservices') return 'nosservices';
    if (path === '/Services/Formation') return 'formation';
    if (path === '/contactus') return 'contact';
    return '';
  };

  const activeItem = getActiveItem(location.pathname);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className='mytop'>
      <div className='topLeftlog'>
        <Link to='/accueil'>
          <img className='Imglogosal' src={log} alt='' />
        </Link>
      </div>
      <div>
        <ul></ul>
      </div>

      <input type='checkbox' id='menu-bar' checked={isMenuOpen} onChange={() => setIsMenuOpen(!isMenuOpen)} />

      <label htmlFor='menu-bar'>
        <FaBars />
      </label>
      <nav className='navbarTOP'>
        <ul>
          <li>
            <Link to='/accueil' className={`salimaNavLink ${activeItem === 'accueil' ? 'active' : ''}`} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faHome} /> Accueil
            </Link>
          </li>
          <li>
            <Link to='/apropos' className={`salimaNavLink ${activeItem === 'apropos' ? 'active' : ''}`} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faInfoCircle} /> À propos
            </Link>
          </li>
          <li>
            <Link to='/nosservices' className={`salimaNavLink ${activeItem === 'nosservices' ? 'active' : ''}`} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faGears} /> Services
            </Link>
          </li>
          <li>
            <Link to='/Services/Formation' className={`salimaNavLink ${activeItem === 'formation' ? 'active' : ''}`} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faUsers} /> Formations
            </Link>
          </li>
          <li>
            <Link to='/contactus' className={`salimaNavLink ${activeItem === 'contact' ? 'active' : ''}`} onClick={handleLinkClick}>
              <FontAwesomeIcon icon={faPhone} /> Contact
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}
