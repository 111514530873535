import React  from "react";

import { useParams } from "react-router-dom";
const FullCard= ({data}) => {
  
    const{ id} = useParams();

    return (
     <div>
        {data.filter((card)=> card.id === id).map((card,index) => (
           
                <h1>
                    {card.name}
                </h1>
               
           
                
        ))}

     </div>



   
    )
  
}
export default FullCard;