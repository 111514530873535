import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formsecurite() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://img.over-blog-kiwi.com/1/18/83/09/20200429/ob_8de675_formation-secourisme-securite-paris.jpg'></img>
  </div>
  <p  className='myparagtime' >Secourisme premiers soins et sécurité du personnel.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.a3fexpertises.fr/upload-articles/formation-incendie.jpeg'></img>
  </div>
  <p  className='myparagtime' >Sécurité incendie et lutte contre le feu.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.centre-formation-avignon.com/form/uploads/client_formations/mini/29_0_agent%20securite%20incendie.jpg'></img>
  </div>
  <p  className='myparagtime' >Evacuation, mise en sécurité du personnel et mise en place d’un exercice d’évacuation.</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://france3-regions.francetvinfo.fr/image/MLpVtNFwGXX7OU-HeaHO5SO31rA/800x450/filters:format(webp)/regions/2020/06/09/5edfa0d2645ca_img_2879-4856664.jpg'></img>
  </div>
  <p  className='myparagtime' >Equipe de première intervention.</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://passpro.fr/sites/default/files/2022-04/e%CC%81quipier%20seconde-page-001.jpg'></img>
  </div>
  <p  className='myparagtime' > Equipe de seconde intervention.     </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.alertis.fr/wp-content/uploads/2021/04/formation-port-des-epi.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation des responsables de sécurité.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.preventis.com.tn/wp-content/uploads/2020/08/Plan-operation.jpg'></img>
  </div>
  <p  className='myparagtime' >Plan d’intervention interne.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.officiel-prevention.com/uploads/dossiers/5e8351590c6aa.jpeg'></img>
  </div>
  <p  className='myparagtime' >Entretien des moyens de lutte contre l’incendie.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://margot-duclot-avocat.com/wp-content/uploads/2020/10/Risques-professionnels.png'></img>
  </div>
  <p  className='myparagtime' >Prévention des accidents de travail et les maladies professionnelles.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://img.over-blog-kiwi.com/1/36/06/35/20150504/ob_46d517_geste.jpg'></img>
  </div>
  <p  className='myparagtime' >Gestes et postures au travail.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.supplychaininfo.eu/wp-content/uploads/2019/09/risques-professionnels-circulation.jpg'></img>
  </div>
  <p  className='myparagtime' >Gestion des risques liés à la manutention mécanique et la circulation interne de l’entreprise.</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://environnement.brussels/sites/default/files/styles/social_share/public/2022-09/ThinkstockPhotos-531697358.jpg?itok=O_dqfGPw'></img>
  </div>
  <p  className='myparagtime' >Gestion des risques liés à l’utilisation des substances chimiques et maîtrise des produits dangereux.</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.inrs.fr/dms/inrs/img/dossiers/risque-electrique/diaporama-01/risque-electrique-branchement-armoire-2012-012-023/risque-electrique-branchement-armoire-2012-012-023.jpg'></img>
  </div>
  <p  className='myparagtime' >Détection, prévention et protection des risques liés à l’électricité.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://prpsafety.fr/wp-content/uploads/2020/04/autorisations-de-conduite.jpg'></img>
  </div>
  <p  className='myparagtime' >Habilitation des conducteurs d’engins de levage et de chantiers.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://static.wixstatic.com/media/36866e_cc43160f477b49b3a15cd939b84f8322~mv2.jpg/v1/fill/w_640,h_424,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/36866e_cc43160f477b49b3a15cd939b84f8322~mv2.jpg'></img>
  </div>
  <p  className='myparagtime' >Sauvetage travail en hauteur et évacuation d’urgence.</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://content.preventionbtp.fr/media/cache/large/uploads/images/08/0826bb3cf20821735626a2a6b1c1aac2cc49a94d.jpg'></img>
  </div>
  <p  className='myparagtime' >Espaces confinés.</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.le-centre-formation.fr/wp-content/uploads/2015/12/formation-echafaudage-fixe-et-roulant-1024x683.jpg'></img>
  </div>
  <p  className='myparagtime' >Montage, démontage et utilisation des échafaudages.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.rostaingshop.fr/media/amasty/blog/uploads/2022/10/shutterstock_768268270-1024x682.jpg'></img>
  </div>
  <p  className='myparagtime' >Enquête après accident de travail.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.blog-qhse.com/hubfs/unnamed%20%2819%29.jpg'></img>
  </div>
  <p  className='myparagtime' >Audit interne de la sécurité et de la santé au travail.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://blog.ncoi.be/wp-content/uploads/2020/06/helm_bril_shutterstock_213327433_5abd89cd78da4ce523c4ce044de44251_2000.jpg'></img>
  </div>
  <p  className='myparagtime' >Les exigences du système de management de la santé et de la sécurité au travail.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://condoracademy.dz/wp-content/uploads/2021/08/iso-45001.jpg'></img>
  </div>
  <p  className='myparagtime' >Audit Interne du système de Management de la sécurité selon la norme ISO 45001 et l’ISO 19011.</p>
</div>

</div>   


  </div>
</section>
<Bizzard/>

</div>
  )}