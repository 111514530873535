import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import  './ourservices.css' ;
import exper from './expert.png'
import rslts from './resultat.png'
import innovatio from './puzzle.png'
import veille from './legal-paper.png'
import auditt from './auditt.png'
import audit2 from './audit2.png'

import audit from './audit.png'
import QHSE from './social-care.png'
import formation from './education.png'
import plan from './home.png'
import Footer from '../MYFOOTER/Bizzard'
import iso from './iso1.png'
import Bizzard from '../MYFOOTER/Bizzard';
import Timeline from "../Timeline/Timeline";
import Timeline2 from '../Timeline2/Timeline2';
import { Link } from 'react-router-dom';
import Slideshow from '../Realwork/SlideShow';
import MYCARD from '../MYCARD/MYCARD';
gsap.registerPlugin(ScrollTrigger);

export default function Salimaserv() {

  return (
    <div id='salimaserv'>







<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12">
          <h4 ><span className='firstpart'>Quels sont nos</span> SERVICES?</h4>
          <hr class="star-primary"/>

          <p>Chez Salima, nous offrons une gamme de services de conseil et de
formation conçus pour aider les entreprises de l'industrie pétrolière et
gazière à réussir. <br/>Notre équipe d'experts possède des années
d'expérience et des connaissances approfondies dans des domaines
tels que la conformité légale et réglementaire,<br/> la gestion des risques
et la santé, la sécurité et l'environnement (HSE).</p>
        </div>
        <div class="col-lg-4 col-sm-6">
        <Link to="/veille">

          <div class="item">
          <img className="veille"
    src={veille}
     alt="" />
       
            <h3 className='ourtitre'>Veille  juridique et réglementaire </h3>
            <p>Nous aidons les
entreprises à se tenir au courant des dernières lois et
réglementations, afin de garantir leur conformité et d'éviter des
amendes et des pénalités coûteuses.</p>
          </div>
          </Link>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> 
          <img className="audit"
    src={auditt}
     alt="" />
       
            <h3 className='ourtitre'> L'audit </h3>
            <p> Nos services d'audit aident les entreprises à identifier
les domaines à améliorer et à développer des stratégies pour
réduire les risques et améliorer l'efficacité.
</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item"> 
          <img className="QHSE"
    src={QHSE}
     alt="" />
       
            <h3 className='ourtitre'> Étude et assistance en matière de QHSE </h3>
            <p> nous offrons une
gamme de services pour aider les entreprises à améliorer
leurs pratiques QHSE, y compris l'évaluation des risques,
l'enquête sur les incidents et l'élaboration d'un programme de
sécurité.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6 text-reset  text-decoration-none"  >
        <Link to='/Services/Formation'>
          <div class="item">  
            <img className="formation"
    src={formation}
     alt="" />
       
            <h3 className='ourtitre'  >Formations</h3>
            <p> Notre équipe de formateurs propose une série de
cours sur des sujets tels que la culture de la sécurité,
l'identification des dangers et les interventions d'urgence,
adaptés aux besoins spécifiques de chaque entreprise.
</p>
          </div>
          </Link>
        </div>
        <div class="col-lg-4 col-sm-6">
        <div class="item">    <img className="iso"
    src={iso}
     alt="" />
       
            <h3 className='ourtitre'>Mise en place de systèmes de gestion </h3>
            <p>Nous aidons les
entreprises à mettre en place des systèmes de gestion qui
répondent aux exigences de normes internationales telles que
ISO 9001, ISO 14001 et ISO 45001.</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="item">    <img className="plan"
    src={plan}
     alt="" />
       
            <h3 className='ourtitre'>Plans  </h3>
            <p>Nous vous accompagnons dans les prestations architecturales en réponse aux exigences des parties intéressées afin d'opter les autorisations nécessaires, la création des signalisations ciblées, des plans de circulation et d’évacuation en 2D/3D selon les réglements et les normes en vigueurs.
</p>
          </div>
        </div>

      </div>
 
    </div>

    <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Pourquoi choisir </span> SALIMA?</h4>
        <hr class="star-primary"/>

          <p>Chez Salima, nous adoptons une approche collaborative pour chaque
projet. Nous travaillons en étroite collaboration avec chaque client 
pour comprendre  <br/> leurs besoins et objectifs uniques, et développer des
solutions personnalisées qui répondent à leurs défis spécifiques.
</p>
</div>
    </div>
    </div>

<div class="flexbox">
        <div class="flexcard flexcardGreen">
            <div class="flexcardNumber flexcardNumberGreen">01</div>
            <div class="flex flexcardTitle">Expertise</div>
            <div class="flex flexcardText"> Notre équipe de consultants et de formateurs
possède des années d'expérience et des connaissances
approfondies dans des domaines tels que la conformité légale
et réglementaire, la gestion des risques et l'HSE.
 </div>
            <div class="flex flexcardImg"><img class="flexcardimgItem"
                    src={exper} alt=""/></div>
        </div>
        <div class="flexcard flexcardBlue">
            <div class="flexcardNumber flexcardNumberBlue">02</div>
            <div class="flex flexcardTitle"> Les résultats</div>
            <div class="flex flexcardText">Nous nous engageons à fournir des résultats
qui dépassent les attentes, et nous avons fait nos preuves en
aidant les entreprises à améliorer leurs pratiques et à atteindre
leurs objectifs.
</div>
            <div class="flex flexcardImg"><img class="flexcardimgItem"
                    src={rslts} alt=""/></div>
        </div>
        <div class="flexcard flexcardOrange">
            <div class="flexcardNumber flexcardNumberOrange">03</div>
            <div class="flex flexcardTitle">L'innovation</div>
            <div class="flex flexcardText">Nous sommes toujours à la recherche de
solutions nouvelles et innovantes pour aider nos clients à
réussir, et nous nous tenons au courant des dernières
tendances et des meilleures pratiques du secteur.</div>
            <div class="flex flexcardImg">
                <img class="flexcardimgItem"
                    src={innovatio} alt=""/></div>
        </div>
       
</div>

    
    <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Nos </span> traveaux</h4>
        <hr class="star-primary"/>

        
</div></div>   </div> 
<Timeline2/>
{ /*
<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Nos </span> Formations</h4>
        <hr class="star-primary"/>

        
  </div></div>   </div> 
 <Timeline/>*/ }

    <Bizzard/>
    
    </div>
  )
}
