import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formlean() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://negociosyempresa.com/wp-content/uploads/2023/02/frases-de-mejora-continua-kaizen.jpg'></img>
  </div>
  <p  className='myparagtime' >5S et Kaizen au service de l’amélioration permanente.

</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.asaconseil.fr/media/actions_correctives_58596c47dd45a.jpg'></img>
  </div>
  <p  className='myparagtime' >Traitement des actions correctives et préventives.

</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcStMtQeTdITOgLDOGfjF6wgOAoycxeGtM34ZUdFainV-0DnC3yXYtC6_mBtXEQmQ8l2SJk&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Contrôle encours de production et autocontrôle.

</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://dlf-industrie.com/wp-content/uploads/2018/11/DLF-R%C3%A9seausociaux91.jpg'></img>
  </div>
  <p  className='myparagtime' >Contrôle intermédiaire et Contrôle final.


</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.praetoriate.com/wp-content/uploads/5s.jpg'></img>
  </div>
  <p  className='myparagtime' >Les cinq S au service de l’amélioration permanente.

</p>
</div>

</div>   

<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.action-ergo.fr/IMG-Produit-conception-mobilier/5-guichet-reception-client.PNG'></img>
  </div>
  <p  className='myparagtime' >Organisation et aménagement des postes de travail.


</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://backend.quadient.com/files/default/2022-07/top-five-customer-journey-mistakes.jpg'></img>
  </div>
  <p  className='myparagtime' >Simplification de travail.

</p>
</div>

</div>   


  </div>
</section>
<Bizzard/>

</div>
  )}