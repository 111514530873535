import "./team.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Profiles from "./Profiles";
import React from "react";
import prof1 from './marouenimg.png'

import { profilesData, responsive } from "./dataTeam";


export default function Team() {
  const profile = profilesData.map((item) => (
    <Profiles
      name={item.name}
      position={item.position}
      url={item.imageurl}
      description={item.description}
      contact={item.contact}

    />
  ));

  return (
    <div className="ser">
 
     
      
      <Carousel  showDots={true} responsive={responsive} >
        {profile}
      </Carousel>
    
    </div>
  );
}
