import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formqualite() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.iep.co.il/wp-content/uploads/2015/02/32382746_ml_QC_Approved1.jpg'></img>
  </div>
  <p  className='myparagtime' >Perfectionnement au contrôle de la qualité.
</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn-blog.picomto.com/wp-content/uploads/2017/12/Comment-g%C3%A9rer-les-non-conformit%C3%A9s-sur-les-lignes-de-production-e1513679208700.jpg'></img>
  </div>
  <p  className='myparagtime' >Maîtrise des non conformités des produits et actions correctives et préventives.
</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2GVpbMjdmpNNEM4BVg43zLPgcvTluTUcB67x_qfxWhU3NWA5PkyXdgIcP__aA62d_j0E&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Techniques de résolution de problèmes.
</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_jWY1fHUm_Q2o9q2yeaRzTsa-Bq0ZoaKDkRXgAhYrFHXLjyhBdD30aids5LJAXm3OCyE&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Etude et coût de la non qualité et méthodes de réduction.
</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7T6DjUKvFJy07QZlicbsIj5koINPfbjDxE7v8xo7bmDDVticl4Nwmah7R0FRrw5dnKyg&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' > Système de maitrise de réparations de produits et amélioration continue du niveau de la qualité.
    </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.lepavillondesentrepreneurs.fr/wp-content/uploads/2017/10/AdobeStock_97078437.jpeg'></img>
  </div>
  <p  className='myparagtime' >Contrôle intermédiaire et Contrôle final : comment optimiser mes contrôles ?</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.dzentreprise.net/wp-content/uploads/DZE1002.jpg'></img>
  </div>
  <p  className='myparagtime' >L’organisation et le suivi de la qualité des produits et élaboration du tableau de bord.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.bdc.ca/globalassets/digizuite/10815-7-steps-solve-operational-problems.jpg'></img>
  </div>
  <p  className='myparagtime' >Résoudre les problèmes matières en fabrication.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://uploads-ssl.webflow.com/5eee44c1632b31c0a15dd472/62dfc41d555ade5579ff2e15_satisfaction%20client.jpg'></img>
  </div>
  <p  className='myparagtime' >Gérer les réclamations et fidéliser les clients.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.tdc-securite.fr/wp-content/uploads/exemple-de-document-unique.jpg'></img>
  </div>
  <p  className='myparagtime' >Les outils statistiques de la qualité.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://euro-symbiose.fr/wp-content/uploads/2020/03/AdobeStock_288956289-scaled.jpeg'></img>
  </div>
  <p  className='myparagtime' >Audit de la qualité interne.
</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.humanperf.com/fr/blog/amelioration-continue/articles/gestion-amelioration-continue/gestion-amelioration-continue-banniere.jpg'></img>
  </div>
  <p  className='myparagtime' >Les outils d’amélioration continue de la qualité.
</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://iso-9001.fr/img/deming.jpg'></img>
  </div>
  <p  className='myparagtime' >Les cercles de la qualité.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.lebigdata.fr/wp-content/uploads/2023/01/Management-de-la-qualite.jpg'></img>
  </div>
  <p  className='myparagtime' >Mise en place d’un plan de suivi de la qualité.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.kivupress.info/wp-content/uploads/2022/10/Certification-ISO-9001.jpg'></img>
  </div>
  <p  className='myparagtime' >Exigences de la norme ISO 9001 v 2015.</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://static.wixstatic.com/media/b00c73_381253ac1a744f4ba61e302ba8a5b074~mv2.png/v1/fit/w_513%2Ch_340%2Cal_c%2Cq_80,enc_auto/file.jpg'></img>
  </div>
  <p  className='myparagtime' >Audit interne selon le référentiel ISO 9001 v 2015.
</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://emdsmi.com/wp-content/uploads/2021/05/iatf-internal-auditor-min.jpg'></img>
  </div>
  <p  className='myparagtime' >Exigences de la norme IATF 16949 v 2016.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.qapeo-conseils.fr/wp-content/uploads/2020/01/VDA-6.3-1024x699.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation VDA 6.3 : 2016 « Qualification d’auditeur de processus »
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.q-experts.com.mx/wp-content/uploads/2023/04/Core-Tools-1024x698.png'></img>
  </div>
  <p  className='myparagtime' >Formation Core Tools : s’approprier les outils exigés par la norme IATF 16949 :2016
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSl1Hir3jRkOpBa2yV_-aTMe57oNA7gcM_WNZEffPI26CMB8mjVyMN-j4eH_TrSj8f_mEs&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Audit interne selon le référentiel IATF 16949 v 2016.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.b2p-consulting.com/assets/images/ISO_45001.jpg'></img>
  </div>
  <p  className='myparagtime' >Les exigences du système de management de la santé et de la sécurité au travail selon ISO 45001 v 2018
</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQBtpBrbobCa27FLSIM3Kv2SU9qYnsHg-_PsWkc6nOkeDIbGeSVc5Dubw_okID7Bx3LpEk&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Management des risques ISO 31000 v 2018

</p>
</div>

</div> 




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.b2p-consulting.com/assets/images/ISO_45001.jpg'></img>
  </div>
  <p  className='myparagtime' >Audit Interne du système de Management de la sécurité selon ISO 45001 et l’ISO 19011.

</p>
</div>

</div> 




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://thuvientieuchuan.org/wp-content/uploads/Cac-buoc-chinh-trong-Quy-trinh-thuc-hien-ISO-14001.jpg'></img>
  </div>
  <p  className='myparagtime' >Les exigences du système de management Environnemental selon la norme 14001 v 2015.

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cqhn.com/wp-content/uploads/2021/12/4_INTERPRETATION-DES-EXIGENCES-DE-LA-NORME-ISO-14001-3-1-scaled.jpeg'></img>
  </div>
  <p  className='myparagtime' >Audit interne du système de management Environnemental selon la norme 14001 et l’ISO 19011.

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://previews.123rf.com/images/rawpixel/rawpixel1504/rawpixel150402571/38514397-groupe-diversit%C3%A9-des-personnes-formation-appareils-num%C3%A9riques-r%C3%A9union-concept.jpg'></img>
  </div>
  <p  className='myparagtime' >Méthodes de résolution du problème en groupe (MRPG).

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.manahijconseil.com/wp-content/uploads/2020/02/statistique-econometrie.jpg'></img>
  </div>
  <p  className='myparagtime' >Maîtrise statistique des Procédés (MSP – SPC).


</p>
</div>

</div> 




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTozerIsJ_TQV5p9ziZN11Z0xApqe6K-KakqGOH1kiMy2-5nFJ2e7IylspleNnCHECo5MQ&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Traitement des produits non conformes.

</p>
</div>

</div> 




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.pilotageindustriel.fr/images/abc.gif'></img>
  </div>
  <p  className='myparagtime' >Diagramme de PARETO / Méthode ABC.

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://uploads-ssl.webflow.com/60f038cc403509ad91320dea/63217ecee6d124e563a474f0_approche-processus-7-avantages-min.jpg'></img>
  </div>
  <p  className='myparagtime' >Organisation de la qualité selon l’approche processus.

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://swiver.io/wp-content/uploads/2021/11/AMDEC.jpg'></img>
  </div>
  <p  className='myparagtime' >AMDEC processus & AMDEC Produit.


</p>
</div>

</div> 




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://groupechd.fr/wp-content/uploads/2017/08/echantillonageurssaf.jpg'></img>
  </div>
  <p  className='myparagtime' >Contrôle par échantillonnage.


</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.seminaire-sherbrooke.qc.ca/upload/edp/perfectionnement/intelligence-artificielle-et-strategies-d-affaires-en-entreprises/photo-vedette.jpg?ver=1'></img>
  </div>
  <p  className='myparagtime' >Formation pratique au contrôle de la qualité.

</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://ressources.regionsjob.com/seo/ObsJob/responsable-qualite.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation de Responsable Qualité.


</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cqhn.com/wp-content/uploads/2022/06/formation-deployer-lAPQP.jpg'></img>
  </div>
  <p  className='myparagtime' >Planification anticipée de la qualité : APQP.


</p>
</div>

</div> 



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.lepavillondesentrepreneurs.fr/wp-content/uploads/2020/07/KPI-RH-1000x500.jpg'></img>
  </div>
  <p  className='myparagtime' >Tableau de bord qualité – Élaboration et analyse des données.


</p>
</div>

</div> 






  </div>
</section>
<Bizzard/>

</div>
  )}