import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formfinance() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.dynamique-entreprendre.com/wp-content/uploads/2017/11/23227136-1450318505023637-713027344-n.jpg'></img>
  </div>
  <p  className='myparagtime' >Les principes comptables de base.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.lecoindesentrepreneurs.fr/wp-content/uploads/2015/06/Le-bilan-comptable.png'></img>
  </div>
  <p  className='myparagtime' >Lire et comprendre le bilan et le compte de résultat.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.abc-formationcontinue-blog.com/wp-content/uploads/2022/03/Trame-Blog-70.png'></img>
  </div>
  <p  className='myparagtime' >Formation à la comptabilité analytique.</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://img-0.journaldunet.com/NNRoVileJqeMwK32mMb2mPgiIlo=/1500x/smart/723ccfa7a6b34325b105491f780f2895/ccmcms-jdn/10666710.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation à la comptabilité générale.</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.smallbusinessact.com/wp-content/uploads/2019/07/infographie-KPI-v0.png'></img>
  </div>
  <p  className='myparagtime' > Pilotez et améliorez la performance de votre service comptable et financier.   </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.republicoftogo.com/var/site/storage/images/toutes-les-rubriques/eco-finance/batir-une-finance-africaine-solide/2824953-1-fre-FR/batir-une-finance-africaine-solide_i1920.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation à la finance d’entreprise.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://bpifrance-creation.fr/sites/default/files/2019-04/visuel-analysefi_0.png'></img>
  </div>
  <p  className='myparagtime' >Pratique de l’analyse financière : Maîtriser le diagnostic.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.decivision.com/wp-content/uploads/2016/06/tableau-de-bord-finance-1-1024x715.png'></img>
  </div>
  <p  className='myparagtime' >Formation au contrôle de gestion.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.sac-consulting.com/sites/default/files/styles/cover_page_860x570/public/audit.jpg?itok=wn4dBda1'></img>
  </div>
  <p  className='myparagtime' >Audit interne et social.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://d2fl3xywvvllvq.cloudfront.net/wp-content/uploads/2018/03/gestionrisque-e1521124094811.jpg'></img>
  </div>
  <p  className='myparagtime' >Méthodes et outils de gestion pour managers.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.tunidutch.com/wp-content/uploads/2017/11/systeme-fiscal-et-entreprises2-960x700.jpg'></img>
  </div>
  <p  className='myparagtime' >L’essentiel de la fiscalité d’entreprise : Principes et méthodes de base ( perfectionnement à la fiscalité d’entreprise).</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://blog.mooncard.co/hubfs/Cocon%20Note%20de%20frais/Frame%2095-min.jpg'></img>
  </div>
  <p  className='myparagtime' >La gestion de la TVA.</p>
</div>

</div>   


 





  </div>
</section>
<Bizzard/>

</div>
  )}