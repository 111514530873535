import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formmanagment() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://media.istockphoto.com/id/1344197991/fr/vectoriel/pr%C3%A9sentation-des-domaines-de-connaissances-de-la-gestion-de-projet-tels-que-le-co%C3%BBt-le.jpg?s=612x612&w=0&k=20&c=WEiiDYnqV5f6bewFgvdYOAJzAk73op-sf3voHUxfS4A='></img>
  </div>
  <p  className='myparagtime' >Gestion et management de projets.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://lamatrice.org/wp/wp-content/uploads/2019/08/najlepsze-strategie-forex.jpg'></img>
  </div>
  <p  className='myparagtime' >Le pilotage stratégique de projets.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://penserrh.files.wordpress.com/2015/09/unnamed-2.jpg'></img>
  </div>
  <p  className='myparagtime' >Réussir un projet de changement.</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn4.regie-agricole.com/ulf/CMS_Content/1/articles/203533/fiches_comment-les-banques-evaluent-elles-les-projets-d-installation.jpg'></img>
  </div>
  <p  className='myparagtime' >Evaluation financière de projets.</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.daf-mag.fr/Img/BREVE/2020/12/355489/Quelle-methode-projet-votre-transformation--F.jpg'></img>
  </div>
  <p  className='myparagtime' >  Chef de projet : Développez votre leadership, Développement personnel et efficacité professionnelle ,Améliorer ses relations professionnelles,Renforcer ses pouvoirs de conviction.          </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.webmarketing-com.com/wp-content/uploads/2022/08/etude-de-faisabilite-scaled-e1661265063351.jpg.webp'></img>
  </div>
  <p  className='myparagtime' >Mesurer la rentabilité et la faisabilité de vos projets.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.kara.fr/wp-content/uploads/2021/05/cahier-des-charges-kara.jpg'></img>
  </div>
  <p  className='myparagtime' >Elaborer un cahier de charges fonctionnel.</p>
</div>

</div>   




  </div>
</section>
<Bizzard/>

</div>
  )}