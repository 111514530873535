import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import quote2 from './quote2.png'
import quote from './quote2.png'

import './value.scss'
import More from './More';
import imgdaoued from './marouenimg.png'
import exl from './excellent.png'
import innovation from './innovation.png'
import Team from '../Team/Team';
import Myslider from '../Slider/Myslider';
import sal from './logosalima1.png'
import Nosvaleurs from '../Nos valeurs/Nosvaleurs'
const Value = () => {

return (
<div>

<div className="row">




<div className="card">
      <p>
        La société Salima de gestion des risques professionnels est un bureau d’étude, de consulting et de formation.
Nous accompagnons nos clients à la mise en conformité réglementaire et nous facilitons l’intégration d’une culture HSE au sein des entreprises tout en faisant gagner du temps, de l'énergie tout en optimisant les budgets.
</p>
            <p>Chez Salima, nous sommes passionnés par le fait d'aider nos clients
à réussir. Notre parcours a commencé en 2010, lorsque le fondateur
M. Marouen Daoud a vu le besoin d'un bureau de conseil et de
formation qui fournissait des solutions innovantes et un service
exceptionnel aux clients de l'industrie pétrolière et gazière.
</p>
<p>
Depuis, nous avons étendu nos services à la veille juridique et
réglementaire, à l'audit, à l'étude et à l'assistance en matière de
QHSE, de formation et de systèmes de gestion. Nous travaillons avec
des clients tant au niveau national qu'international et notre équipe
d'experts se consacre à les aider à atteindre leurs objectifs.</p>

        </div>





      
        
              <img className="daoued"
  src={imgdaoued}
   alt="" />
    </div>
    <p className='nameanimation'>Marwen DAOUED</p>
    <p className='nameanimation2'>Votre expert en SST </p>





</div>
) }
export default Value;
