import React from 'react'
import "./timeline.scss"
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Timeline() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">

  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
  <div class="timeline-img-header1">
    <h2>GTSP </h2>
  </div>
  <div class="date">2023</div>
  <p  className='myparagtime' >Formation en sécurité de travail et les maladies professionnels +visite indistrueille au sein de la sociéte </p>
</div>

</div>   





    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
        <div class="timeline-img-header">
          <h2>Nokia</h2>
        </div>
        <div class="date">25 MAY 2016</div>
        <p  className='myparagtime' >Lorem rahma dolor sit amet, consectetur adipisicing elit. Maxime ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed nulla voluptatem recusandae dolor, nostrum excepturi amet in dolores. Alias, ullam.</p>
      </div>

    </div>   







    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
        <div class="timeline-img-header">
          <h2>Incendie</h2>
        </div>
        <div class="date">25 MAY 2016</div>
        <p  className='myparagtime' >Lorem rahma dolor sit amet, consectetur adipisicing elit. Maxime ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed nulla voluptatem recusandae dolor, nostrum excepturi amet in dolores. Alias, ullam.</p>
      </div>

    </div>   


    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
        <div class="timeline-img-header">
          <h2>Card Title</h2>
        </div>
        <div class="date">25 MAY 2016</div>
        <p  className='myparagtime' >Lorem rahma dolor sit amet, consectetur adipisicing elit. Maxime ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed nulla voluptatem recusandae dolor, nostrum excepturi amet in dolores. Alias, ullam.</p>
      </div>

    </div>   





    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInLeft">
        <div class="timeline-img-header">
          <h2>Card Title</h2>
        </div>
        <div class="date">10 JULY 2016</div>
        <p  className='myparagtime' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed nulla voluptatem recusandae dolor, nostrum excepturi amet in dolores. Alias, ullam.</p>
      </div>
    </div>   

    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
        <div class="timeline-img-header">
          <h2>Card Title</h2>
        </div>
        <div class="date">30 JULY 2016</div>
        <p  className='myparagtime' >Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime ipsa ratione omnis alias cupiditate saepe atque totam aperiam sed nulla voluptatem recusandae dolor, nostrum excepturi amet in dolores. Alias, ullam.</p>
      </div>
    </div>  






  </div>
</section>
<Bizzard/>

</div>
  )}