import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import marwen from '../Newsliders/marouenimg.png';
import './ANIMATION.scss';
import since from './SINCE.png';
gsap.registerPlugin(ScrollTrigger);

function ANIMATION() {
  const imageRef = useRef(null);
  const mytexto = useRef(null);
  const textRefsoc = useRef(null);
  const imageRefsoc = useRef(null);

  useEffect(() => {
    const el = imageRef.current;
    const el1 = mytexto.current;
    const eltext = textRefsoc.current;
    const elimg = imageRefsoc.current;

    const mediaQuery = window.matchMedia('(min-width: 768px)');

    const duration = mediaQuery.matches ? 6 : 3; // Réduire la durée à 3 secondes si la largeur est inférieure à 768px

    gsap.fromTo(
      [el, el1, eltext, elimg],
      { y: '100%', opacity: '0' },
      {
        y: '0%',
        opacity: '100%',
        duration,
        scrollTrigger: {
          trigger: '.section-head1', // Déclencheur du scroll
          toggleActions: 'restart none none none',
        },
      }
    );
  }, []);

  return (
    <div>
      <div class="feat bg-gray pt-5 pb-5">
        <div class="row">
          <div class="section-head1 col-sm-12">
            <h4 className="lineUp">
              <span>QUI SOMMES </span> NOUS ?
            </h4>
            <hr class="star-primary" />
          </div>
        </div>
      </div>

     
    <div className="row">

  

<div className="animsociete" ref={textRefsoc} >
<p>
        La société Salima de gestion des risques professionnels est un bureau d’étude, de consulting et de formation.
Nous accompagnons nos clients à leurs mise en confirmité réglementaire en matière de qualité , santé ,sécurité au travail
 , environnement et social et nous facilitons l’intégration d’une culture HSE au sein des organismes
 tout en faisant gagner du temps, de l'énergie tout en optimisant les budgets.
</p>
</div>
<img ref={imageRefsoc} src={since} alt="" className="logoanimsoci" />
</div>

      <div className="row">

  


        <div ref={mytexto} className="mytexto">
        <p>Chez Salima, nous sommes passionnés par le fait d'aider nos clients
à réussir. Notre parcours a commencé en 2020, lorsque le fondateur
M. Marouen Daoud a vu le besoin de créer un bureau de conseil et de
formation qui fournissait des solutions innovantes et un service
exceptionnel aux clients de diffrérents secteurs.
<br/>
Depuis, nous avons étendu nos services par:
<ol >  <li>
 la mise en place d'une plateforme de veille légale et réglementaire en matiére hygéne ,sécurité ,sureté,
environnement, qualité ,sociale et socitale . </li>

 <li>

l'assistance des organismes à la mise en place des systémes de managment 
de la qualité, de la securité SST et SMS ,enviromental ,
</li>
<li>
 l'étude et à l'assistance en matière de
QHSE, de formation et de systèmes de gestion. Nous travaillons avec
des clients tant au niveau national qu'international et notre équipe
d'experts se consacre à les aider à atteindre leurs objectifs.</li> </ol>
</p>
        </div>
        <img ref={imageRef} src={marwen} alt="" className="logoapp" />
      </div>


    </div>
  );
}

export default ANIMATION;