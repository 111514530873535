import { useRef } from "react";
import './lastcontact.css';
import emailjs from 'emailjs-com';
import Bizzard from "../MYFOOTER/Bizzard";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Lastcontact() {
  const form = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert("Please verify that you're not a robot.");
      return;
    }

    emailjs.sendForm('service_ywazg08', 'template_50pw3qq', form.current, 'UFrf0ZKZsnXQVpxT8')
      .then((result) => {
        console.log(result.text);
        alert('Votre message a été envoyé avec succès!');
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
        alert("Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer plus tard.");
      });
  }

  return (
    <section>
      <section id="contact">
        <div className='googelmapss'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1377.979172070485!2d10.755667505189827!3d34.77057148219058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1301d2fd33dca451%3A0xe11b949dbef1626f!2sSfax%20Nord%2C%20Sfax!5e0!3m2!1sfr!2stn!4v1684236422886!5m2!1sfr!2stn"
            width="100%"
            height="300"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade" className='iframemaps'>
          </iframe>
        </div>
      </section>

      <section className="contact_container" id="link-6">
        <form id="contact-form" className="contact_contain" role="form" ref={form} onSubmit={handleSubmit}>
          <h2 className="contact_title">Contact</h2>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="from_name" id="from_name" />
                <span className="contact_subtitle">Nom</span>
                <span className="contact_line"></span>
              </div>
            </div>
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="prenom" id="prenom" />
                <span className="contact_subtitle">Prénom</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="from_email" id="from_email" />
                <span className="contact_subtitle">Adresse mail</span>
                <span className="contact_line"></span>
              </div>
            </div>
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="phone" id="phone" />
                <span className="contact_subtitle">Télephone</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox ">
                <textarea className="contact_textarea" required id="message" rows="10" name="message" onBlur={(e) => { console.log(e.target.value) }}></textarea>
                <span className="contact_subtitle">Message</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox contact_textarea">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={(value) => setRecaptchaValue(value)}
                />


                <input type="submit" value="Envoyer" id="submit" />
              </div>
            </div>
          </div>
        </form>
      </section>
      <Bizzard />
    </section>
  )
}
