import prof1 from './marouenimg.png'
import i1 from './i1.jpeg'
import i2 from './i2.jpeg'
import i3 from './i3.jpeg'
import i4 from './i4.jpeg'

export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    
  },
};

export const profilesData = [
  {
    id: 1,
    imageurl:
        prof1,
    
        name: "Daoued Marwen",
    position: "Gérant ",
    description: "Est à votre disposition pour tous  détails techniques et offres commerciales.",
    contact : "dg@salima-sst.com",
  },
  {
    id: 2,
    imageurl:
  i3,
    name: "Dorre BESBES",
    position: "Achitecte",
    description: "Est à votre disposition pour tous préstations de relevé architectural , proposition de solution architectural en réponse au fonctionnalité des batiments ou aux exigence réglementaires, conception des plans d'évacuation ,de circulation et des plans exigée dans les differents études.",
   contact : " archi@salima-sst.com",
  
  },
  {
    id: 3,
    imageurl:
 i1,
    name: "Wafa AMOUS",
    position: "Responsable administratif ",
    description: "Sera à votre acceuil afin de comprendre vos besoins et vous dirige , Mme wafa sera votre interloccuteur primaire de la société",
  contact : " contact@salima-sst.com",

},
  {
    id: 4,
    imageurl:
i2,
    name: "Rahma LASSOUED", 
    position: "Responsable IT",
    description: "Elle vous supporte pour vos préférences et vos réclamations informatiques lors de l'utilisation de nos produits web ",
    contact : " support@salima-sst.com",

  },
  {
    id: 5,
    imageurl:
    i4,
    name: "Khayri ROMDHANI ",
    position: "Responsable technico commerciale",
    description: "Sera à votre disposition pour vous présenter nos différents produits et services et vous accompagnez à bien planifier les différents prestationsouhaiter",
    contact : " commercial@salima-sst.com",

 
  },
  
  
];
