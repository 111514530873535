import React from "react";
import  './bizzard.css' ;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function Bizzard() {
    return (
        <div className="footer5">
        <div className="footer-content">
        <h3>Société SALIMA</h3>


        La société Salima de gestion des risques professionnels est un bureau d’étude, de consulting et de formation.
Nous accompagnons nos clients à leurs mise en confirmité <br/> réglementaire en matière de qualité , santé ,sécurité au travail
 , environnement et social et nous facilitons l’intégration d’une culture HSE<br/> au sein des organismes
 tout en faisant gagner du temps, de l'énergie tout en optimisant les budgets.


<ul class="socials">

<li>
  <a
    href="https://www.google.com/maps/search/?api=1&query=Route+de+ceinture+Km+4+de+Teniour+vers+Route+de+Tunis%2C+Sfax%2C+Tunisie"
    target="_blank"
    rel="noopener noreferrer"
  >
    <FontAwesomeIcon icon={faMapMarkerAlt} />
    Route de ceinture Km 4 de Teniour vers Route de Tunis, Sfax, Tunisie

  </a>
</li>




   <li><a href="#"><FontAwesomeIcon icon={faMapMarkerAlt}  /></a> Route de ceinture Km 4 de Teniour vers Route de Tunis, Sfax, Tunisie</li>
   <li><a href="#"><FontAwesomeIcon icon={faEnvelope}  /></a> contact@salima-sst.com</li>


   <li>
  <Link to="https://www.linkedin.com/in/soci%C3%A9t%C3%A9-salima-299405279/" target="_blank">
    <FontAwesomeIcon icon={faLinkedin} />
      Société SALIMA

  </Link>
</li>


<li>
  <Link to="tel: +21636334191">
    <FontAwesomeIcon icon={faPhone} />
    +216 36 33 41 91  </Link>

</li>

   <li><a href="#"><FontAwesomeIcon icon={faEnvelope}  /></a> dg@salima-sst.com</li>


   <li>
  <Link to="https://www.linkedin.com/in/marouen-daoud-2b587915a/" target="_blank">
    <FontAwesomeIcon icon={faLinkedin} />
      Marouen Daoud

  </Link>
</li>

<li>
  <Link to="tel:+21620205868">
    <FontAwesomeIcon icon={faPhone} />
  +216 20 20 58 68
  </Link>

</li>

</ul>
<div class="footer-bottom">
<p>copyright &copy;2023 <a href="#">Société SALIMA</a>  </p>
<div class="footer-menu">
 <ul class="f-menu">



    <li> <Link to="/">Accueil</Link>  </li>
    <li><Link to="/apropos">À-PROPOS</Link></li>
    <li>    <Link to="/nosservices">SERVICES</Link>
</li>
    <li>    <Link to="/Services/Formation">FORMATIONS</Link>
</li>
    <li>    <Link to="/contactus">CONTACT</Link>
</li>

 </ul>
</div>
</div>
</div>   </div>   )}
    export default Bizzard ;