import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"
let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Timeline2() {
  return (
    <div className='mybodytime'>


<section class="timeline">


  <div class="containertime">
  <div class="timeline-item">



<div class="timeline-img"></div>

<div class="timeline-content timeline-card js--fadeInRight">
 
  <p  className='myparagtime' >Realisation des visites et des rapports de diagnostiques par rapport à la confirmité réglementaire dans des secteurs bien déterminée : cas de l'obtenation des attestation de prérentrée des autorisations d'ouverture etablissements dangereuses,  insalubres ou incommodes (TSK ,Med chaussures ,MEL-T, CROWN , etc.)

</p>
</div>

</div>  

    <div class="timeline-item">



      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
       
        <p  className='myparagtime' >Collaboration avec les architectes dans le cadre des conception et/ou réamenagement , readaptation des espaces dont le respect des cahier des charges ,exigences et autres, de sécurité incendie; hygiene  ,santé  et envirennement</p>
      </div>

    </div>  




    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content js--fadeInLeft">
      <p  className='myparagtime' >La mise en place des systems de managment en qualité , santé sécurité au travail et environnement 
conformément au exigences des normes
             ISO 9001.2015 ,ISO 45001:2018 et ISO 14001:2015 (Nedco ,STE THINK DIFFERENT )</p>


      </div>
    </div>   

    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content js--fadeInRight">
        <p  className='myparagtime' >La réalisation des etudes d'impacte et dépollution environnementale , les études de danger ,les notices d'hygéne , les plans d'opérations internes et touts autres études techniques nécessaire pour la conformité de la réglementation de sécurité incendie .  </p>
      </div>
    </div>   

    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInLeft">
       
        <p  className='myparagtime' >La réalisation des actions de formation spécifiques en matiére de santé et sécurité de travail exécutif (CACES , travail en hauteur , cordiste , habilitation électrique ,équipe premiere et deuxieme intervention , ergonomie , etc)    </p>
      </div>
    </div>   

    <div class="timeline-item">

      <div class="timeline-img"></div>

      <div class="timeline-content timeline-card js--fadeInRight">
        <p  className='myparagtime' >Conception et réalisation de traveaux de traçage de sol et de signalisations réglementaire  </p>
      </div>
    </div>  

   


  </div>
</section>

</div>
  )}