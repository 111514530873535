import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formmktg() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.abcportage.fr/wp-content/uploads/2019/10/Consultants-voici-les-techniques-de-n%C3%A9gociation-commerciale.jpg'></img>
  </div>
  <p  className='myparagtime' >Techniques de ventes et techniques de négociation commerciale.
</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://evolutis-formation.fr/wp-content/uploads/2016/11/bd9435f235cb4005045f2e6c43e9346a_XL.jpg'></img>
  </div>
  <p  className='myparagtime' >Gestion du portefeuille clients.
</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.net-actuality.org/wp-content/uploads/2022/11/digital-design-businessman-show-increase-market-graphic-2-1.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation aux techniques de marketing.
</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://machronique.com/wp-content/uploads/2020/04/marketing-digital.jpg'></img>
  </div>
  <p  className='myparagtime' >Le marketing opérationnel.

</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://blog.hubspot.fr/hubfs/media/analysedonneesmarketing.jpeg'></img>
  </div>
  <p  className='myparagtime' >Le marketing stratégique
</p>
</div>

</div>   



  </div>
</section>
<Bizzard/>

</div>
  )}