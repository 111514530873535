import React  from "react";
import Card from "./Card";
import "./test.css";
import Bizzard from "../pages/MYFOOTER/Bizzard";
import Contactformations from "../CardsFormations/Contactformations"
const Hero= ({data}) => {
    return (
      <div>

        <div className='hero' >
          <Card data={data}/>

      </div>      
              <span className="plus-for">Pour plus d'informations sur nos formations et nos formateurs , merci de nous contacter. </span>
              <Contactformations/>
     <Bizzard/>
</div>
    )
}
export default Hero;