


import Services from "./pages/Services/Services"
import ReactDOM from "react-dom/client";

import React ,{Component} from 'react'
import { BrowserRouter as Router, Routes, Route ,useLocation } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useEffect } from 'react';

import Hero from "./CardsFormations/Hero";
import FullCard from "./CardsFormations/FullCard";
import data from "./CardsFormations/content"
import Card from "./CardsFormations/Card";
import HeroServices from "./CardsServices/HeroServices";
import { Data } from "./CardsServices/data";

import Top from "./pages/Mytopbar/Top"

import Value from "./pages/Value/Value";
import Team from "./pages/Team/Team";
import Salimaserv from "./pages/our services/Salimaserv";
import New from "./pages/new/New";
import Aboutus from "./pages/Value/Aboutus";
import Quote from "./pages/Value/Quote";
import Bizzard from "./pages/MYFOOTER/Bizzard";
import Society from "./pages/society/Society";
import Timeline from "./pages/Timeline/Timeline";
import  { useState } from 'react';
import Lastcontact from "./pages/Lastcontact/Lastcontact";
import Formsecurite from "./pages/Lastformations/Formsecurite";
import Forminfo from "./pages/Lastformations/Forminfo";
import Formcompet from "./pages/Lastformations/Formcompet";
import Formfinance from "./pages/Lastcontact/Formfinance";
import Formlangues from "./pages/Lastformations/Formlangues";
import Formmanagment from "./pages/Lastformations/Formmanagment";
import Formrh from "./pages/Lastformations/Formrh";
import Formadmin from "./pages/Lastformations/Formadmin";
import Formqualite from "./pages/Lastformations/Formqualite";
import Formasodure from "./pages/Lastformations/Formasodure";
import Formmeetrologie from "./pages/Lastformations/Formmeetrologie";
import Formmktg from "./pages/Lastformations/Formmktg";
import Formlean from "./pages/Lastformations/Formlean";
function App() {
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }
  
  return(
    
    <Router>
   <Top/>
   <ScrollToTop />

    <Routes>
    <Route path="/apropos" caseSensitive={false} element={< Aboutus/>} />

    <Route path="/" caseSensitive={false} element={<Services />} />
    <Route path="/Services/Formation" caseSensitive={false} element={<Hero data={data}  />} />
    <Route path="/accueil" caseSensitive={false} element={<Services />} />
    <Route path="/services" caseSensitive={false} element={<Services />} />
    <Route path="/nosservices" caseSensitive={false} element={<Salimaserv />} />

    <Route path="/contactus" caseSensitive={false} element={<Lastcontact/>} />
   



   
    <Route path="Formations/:name" caseSensitive={false} element={<FullCard data={data}/>} />
      <Route path="Formations/Sécurité" caseSensitive={false} element={<Formsecurite/>} />

      <Route path="Formations/Informatique" caseSensitive={false} element={<Forminfo/>} />
      <Route path="Formations/Gestion des ressources humaines" caseSensitive={false} element={<Formrh/>} />
      <Route path="Formations/Gestion et finances" caseSensitive={false} element={<Formfinance/>} />
      <Route path="Formations/Langues" caseSensitive={false} element={<Formlangues/>} />
      <Route path="Formations/Développement de compétences" caseSensitive={false} element={<Formcompet/>} />
      <Route path="Formations/Management de projets" caseSensitive={false} element={<Formmanagment/>} />
      <Route path="Formations/Gestion administratif" caseSensitive={false} element={<Formadmin/>} />


      <Route path="Formations/Qualité" caseSensitive={false} element={<Formqualite/>} />

      <Route path="Formations/Soudure" caseSensitive={false} element={<Formasodure/>} />

      <Route path="Formations/Metrologie" caseSensitive={false} element={<Formmeetrologie/>} />
      <Route path="Formations/Commercial et marketing" caseSensitive={false} element={<Formmktg/>} />
      <Route path="Formations/Lean Manufacturing-Lean Management" caseSensitive={false} element={<Formlean/>} />


      <Route path="/Formations" caseSensitive={false} element={<Hero data={data}  />} />

        </Routes>


        </Router>

  )
}

export default App;
