import * as React from 'react';
import  './new.css' ;
import $ from 'jquery';
$(function() {
    $('.counternew').counterUp({
      delay: 10,
      time: 1200
    });
  });
  
export default function New() {
  return (
    <div>
    <div class="sectionnew"></div>
  <div class="wrappernew">
    <div class="titlenew">Responsive Counter up Animation HTML CSS & jQuery</div>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit accusantium, dolor eum ipsum repudiandae repellat sapiente, molestias, dolores explicabo laborum corrupti commodi totam molestiae magni ipsam saepe aut tempore nemo similique? Fuga in minus amet adipisci ipsa incidunt, quam iure aliquid corrupti asperiores, non quibusdam earum debitis dignissimos modi omnis repudiandae praesentium ad sequi veritatis, odio. Nam, unde! Iste consequatur asperiores doloremque aperiam cupiditate optio eum ducimus voluptatibus, animi illo quam dicta inventore dolore ab autem a, obcaecati, reprehenderit. Natus quasi officiis nulla officia rem placeat aut impedit nemo eum voluptatibus, dolores molestias velit, cupiditate magni illo laudantium beatae facere sequi obcaecati, reprehenderit eveniet nam, ea aperiam hic. Est odit, qui. Aliquid saepe molestiae, inventore. Necessitatibus temporibus, dignissimos, nisi repellendus possimus sed incidunt nam quos enim veritatis corporis ut sint, facere vitae, illum accusamus nesciunt dolore. Doloremque nihil sit, alias molestias sint quos voluptatum voluptatibus sunt quisquam, facere quod pariatur.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta repellendus illo iste eius molestiae natus, esse incidunt. Minus architecto dicta, provident libero. Ipsum culpa, atque ab. Aut incidunt amet optio quo nemo atque eligendi aperiam quisquam aliquid, distinctio mollitia minima, eius repellendus asperiores vero reprehenderit dolore, consequuntur adipisci tenetur! Enim sed, tempora obcaecati perspiciatis illo ducimus maiores aut quae eos, aspernatur sunt unde, quam a praesentium tenetur est quas eveniet dolorum quibusdam hic placeat! Distinctio nostrum assumenda suscipit cupiditate rem amet repudiandae labore neque quis quia dicta, aliquid eum nemo sint, maxime sunt. Cumque suscipit nulla quis velit iure. Consequuntur, ipsa! Veniam error enim itaque ab unde, accusamus asperiores, ratione, debitis similique suscipit aut, qui consectetur quos repudiandae illo impedit.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officiis, sunt ipsum modi. Consectetur nobis vero minus, architecto facere velit explicabo molestiae delectus omnis ad expedita similique inventore placeat incidunt. Sint quasi autem quam illo omnis soluta minus, in quibusdam placeat. Saepe ea magni ducimus, modi, eos quis, at possimus earum ad magnam natus, nihil vero error. Ab ipsum omnis odit ipsam, maxime aspernatur molestiae sit tempore corporis non rerum earum neque voluptatibus provident dolor quod molestias officia. Aspernatur quis magni iste cum quas fugit modi aut ratione repudiandae quaerat eaque blanditiis veritatis quibusdam, reprehenderit eveniet, earum, atque sequi, sunt fugiat?</p>
  </div>
  <div class="counter-upnew">
    <div class="contentnew">
      <div class="boxnew">
        <div class="iconnew"><i class="fas fa-history"></i></div>
        <div class="counternew">724</div>
        <div class="textnew">Working Hours</div>
      </div>
      <div class="box">
        <div class="iconnew"><i class="fas fa-gift"></i></div>
        <div class="counternew">508</div>
        <div class="textnew">Completed Projects</div>
      </div>
      <div class="box">
        <div class="iconnew"><i class="fas fa-users"></i></div>
        <div class="counternew">436</div>
        <div class="textnew">Happy Clients</div>
      </div>
      <div class="box">
        <div class="iconnew"><i class="fas fa-award"></i></div>
        <div class="counternew">120</div>
        <div class="textnew">Awards Received</div>
      </div>
    </div>
  </div>
  
  </div>

  
  
  )
}