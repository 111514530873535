import "./valeurs.css";
<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap" rel="stylesheet"/> 

export default function Valsoc() {

return (
<div>
    <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Pourquoi choisir </span> SALIMA</h4>
        <hr class="star-primary"/>

</div></div>   </div> 
<div id="containervalue">
    
        <div class="cardvalue">
            <div class="circlevalue">             
           EXCELLENCE
</div>
            <div class="infovalue">
                <p>Notre équipe de consultants et de formateurs possède des années d'expérience et des connaissances approfondies dans des domaines tels que la conformité légale et réglementaire, la gestion des risques et l'HSE.</p>
            </div>
        </div>
        <div class="cardvalue">
            <div class="circlevalue">
                 INNOVATION</div>
            <div class="infovalue">
                <p>Nous nous engageons à fournir des résultats qui dépassent les attentes, et nous avons fait nos preuves en aidant les entreprises à améliorer leurs pratiques et à atteindre leurs objectifs.</p>
            </div>
        </div>
        <div class="cardvalue">
            <div class="circlevalue">
                INTÉGRITÉ</div>
            <div class="infovalue">
                <p>Nous sommes toujours à la recherche de solutions nouvelles et innovantes pour aider nos clients à réussir, et nous nous tenons au courant des dernières tendances et des meilleures pratiques du secteur.</p>
            </div>
        </div>

    </div></div>

)
}