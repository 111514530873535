import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formcompet() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.montuteur.fr/wp-content/uploads/2022/05/Financement-formation-hors-CPF-_c-freepik-scaled.jpg'></img>
  </div>
  <p  className='myparagtime' >Détermination et analyse des besoins de formation.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://f.hellowork.com/blogdumoderateur/2022/03/selection-formation-gestion-projet-93.jpeg'></img>
  </div>
  <p  className='myparagtime' >Elaboration et mise en œuvre du plan de formation.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.managementdelaformation.fr/wp-content/uploads/2021/10/RHEXIS_Reperes_Impact_Evaluation_Formation_1_Blog-1280x720.jpg'></img>
  </div>
  <p  className='myparagtime' >Evaluation de l’efficacité des actions de formation.</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.realmediahub.com/wp-content/uploads/2021/08/buzz.jpg'></img>
  </div>
  <p  className='myparagtime' >Le tableau de bord et les ratios de la formation.</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://swiver.io/wp-content/uploads/2022/04/formation-gestion-de-projet.webp'></img>
  </div>
  <p  className='myparagtime' >  La gestion de projet formation.    </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.alertis.fr/wp-content/uploads/2021/04/formation-port-des-epi.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation des responsables de sécurité.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://slti.fr/images/pexels-yan-krukau-7793692_1.jpg'></img>
  </div>
  <p  className='myparagtime' >Amélioration et performance du service formation.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.oiq.qc.ca/wp-content/uploads/2022/04/maestro-zoomsurtroisetapes.jpg'></img>
  </div>
  <p  className='myparagtime' >Ingénierie de formation et ingénierie pédagogique.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.webmarketing-com.com/wp-content/uploads/2019/06/formation-formateur.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation de formateurs. </p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.shutterstock.com/image-photo/competence-skills-business-personal-development-260nw-1911471961.jpg'></img>
  </div>
  <p  className='myparagtime' >Gestion et évaluation de compétences.</p>
</div>

</div>   





  </div>
</section>
<Bizzard/>

</div>
  )}