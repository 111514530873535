import './mycard.css';
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function MYCARD() {
  const cardAnimRef = useRef(null);
  const cardAnimRef2 = useRef(null);
  const cardAnimRef11 = useRef(null);
  const cardAnimRef22 = useRef(null);

  useEffect(() => {
    const el = cardAnimRef.current;
    const el1 = cardAnimRef2.current;
    const el11 = cardAnimRef11.current;
    const el22 = cardAnimRef22.current;

    gsap.fromTo(
      [el, el1, el11, el22],
      { y: '100%', opacity: '0' },
      {
        y: '0%',
        opacity: '100%',
        duration: 4,
        scrollTrigger: {
          trigger: '.declencheur',
          toggleActions: 'restart none none none',
        },
      }
    );
  }, []);

  return (
    <div className="flexbox1">
      <div className="row1">
        <div className="declencheur">
          <div className="flexcard1" ref={cardAnimRef}>
            <img
              src="https://www.prorisk.fr/img/cms/deux-collegues-securite-cropped.jpg"
              alt="Image 1"
              className="cardimganim1"
            />
            <h3 className="MYCARDTITLE">Notre processus</h3>
            <p>notre processus de travail se divise en plusieurs étapes clés. Tout d'abord,
               nous effectuons une évaluation approfondie des conditions de travail, en identifiant les risques 
               potentiels et les points faibles. Ensuite, nous élaborons un plan d'action personnalisé, en mettant 
               en place des mesures préventives et des procédures de sécurité adaptées à chaque entreprise. Nous assurons
                également une formation et une sensibilisation des employés pour les impliquer activement dans la prévention 
                des accidents et des maladies professionnelles. Enfin, nous réalisons un suivi régulier et des audits pour 
                garantir l'efficacité des 
              mesures mises en place et apporter des ajustements si nécessaire</p>
            <div style={{ width: '100%', height: '3px', backgroundColor: '#3F51B5' }}></div>
          </div>
        </div>
        <div className="declencheur2">
          <div className="flexcard1" ref={cardAnimRef11}>
            <img
              src="https://www.portailconstructo.com/sites/default/files/styles/large/public/investire_sst_sst20.jpg?itok=UslGcGjZ"
              alt="Image 3"
              className="cardimganim2"
            />
            <h3 className="MYCARDTITLE">Notre objectif</h3>
            <p>
            Notre bureau de conseil et consulting en SST a pour objectif principal d'accompagner les entreprises dans 
            la mise en place de systèmes de gestion de la santé, de la sécurité et de l'environnement.
             Grâce à notre expertise, nous offrons une assistance complète dans l'élaboration et la mise en
              œuvre de politiques et de procédures adaptées aux normes et réglementations en vigueur. Notre équipe 
              d'experts s'engage à aider nos clients à atteindre leurs objectifs en matière de qualité, 
            de sécurité et de protection de l'environnement, aussi bien au niveau national qu'international.


            </p>
            <div style={{ width: '100%', height: '3px', backgroundColor: '#3F51B5' }}></div>
          </div>
        </div>
      </div>
      <div className="row1">
        <div className="declencheur3">
          <div className="flexcard1" ref={cardAnimRef2}>
            <img
              src="https://www.socotec.fr/s3fs-public/2020-12/risques_sur_chantiers_header_image.jpg"
              alt="Image 2"
            />
            <h3 className="MYCARDTITLE">Notre approche</h3>
            <p>
            Notre approche repose sur une approche globale 
            et intégrée pour garantir la santé et la sécurité au travail. Nous commençons par 
            une analyse approfondie des risques et des besoins spécifiques de chaque entreprise.
             Ensuite, nous élaborons des solutions sur mesure en utilisant les meilleures pratiques de
              l'industrie et en tenant compte des réglementations en vigueur. Notre approche met l'accent sur
               la prévention, en identifiant les dangers potentiels et en mettant en place des mesures de prévention
                efficaces. Nous travaillons en étroite collaboration avec nos clients pour les sensibiliser, former 
                leur personnel et les aider à adopter une culture de sécurité proactive.          </p>
            <div style={{ width: '100%', height: '3px', backgroundColor: '#3F51B5' }}></div>
          </div>
        </div>
        <div className="declencheur4">
          <div className="flexcard1" ref={cardAnimRef22}>
            <img
              src="https://www.akto.fr/content/uploads/2022/11/un-passeport-de-prevention-pour-mieux-gerer-les-formations-en-sante-securite-au-travail.png"
              alt="Image 4"
            />
            <h3 className="MYCARDTITLE">Notre mission</h3>
            <p>
            La mission de notre bureau d'études et de consulting en SST (Santé, Sécurité au Travail) est d'apporter notre expertise et notre accompagnement aux entreprises pour améliorer leurs conditions de travail et réduire les risques professionnels. Notre bureau s'engage à réaliser des analyses approfondies, à évaluer les dangers et à proposer des solutions adaptées pour prévenir les accidents, les maladies professionnelles et promouvoir
             un environnement de travail sûr et sain.             </p>
            <div style={{ width: '100%', height: '3px', backgroundColor: '#3F51B5' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
