import { useRef } from "react";
import emailjs from 'emailjs-com';
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

export default function Lastcontact() {
  const form = useRef();
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!recaptchaValue) {
      alert("Please verify that you're not a robot.");
      return;
    }

    emailjs.sendForm('service_bm9pors', 'template_d8eiji7', form.current, 'UFrf0ZKZsnXQVpxT8')
      .then((result) => {
        console.log(result.text);
        alert('Votre message a été envoyé avec succès!');
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
        alert("Une erreur s'est produite lors de l'envoi du message. Veuillez réessayer plus tard.");
      });
  }

  return (
    <section>


      <section className="contact_container" id="link-6">
        <form id="contact-form" className="contact_contain" role="form" ref={form} onSubmit={handleSubmit}>
          <h2 className="contact_title">Contact</h2>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="from_name" id="from_name" />
                <span className="contact_subtitle">Nom</span>
                <span className="contact_line"></span>
              </div>
            </div>
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="prenom" id="prenom" />
                <span className="contact_subtitle">Prénom</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="from_email" id="from_email" />
                <span className="contact_subtitle">Adresse mail</span>
                <span className="contact_line"></span>
              </div>
            </div>
            <div className="contact_col">
              <div className="contact_inputBox">
                <input type="text" className="contact_text" required name="phone" id="phone" />
                <span className="contact_subtitle">Télephone</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox ">
                <textarea className="contact_textarea" required id="message" rows="10" name="message" onBlur={(e) => { console.log(e.target.value) }}></textarea>
                <span className="contact_subtitle">Message</span>
                <span className="contact_line"></span>
              </div>
            </div>
          </div>
          <div className="contact_row">
            <div className="contact_col">
              <div className="contact_inputBox contact_textarea">
                <ReCAPTCHA
                  sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  onChange={(value) => setRecaptchaValue(value)}
                />


                <input type="submit" value="Envoyer" id="submit" />
              </div>
            </div>
          </div>
        </form>
      </section>
    </section>
  )
}
