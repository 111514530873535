
const data = [
  {
    id: 1,
    name: "Sécurité ",
    image:
   " https://www.afsis-corse.fr/wp-content/uploads/2017/02/securite-incendie.jpg"
  
  
  },
  {
    id: 2,
    name: "Gestion des ressources humaines ",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRpWbJ0cmLnLpfFn-yErrEj8zXkAiYtmwgxdg&usqp=CAU",

  },
  {
    id: 3,
    name: "Développement de compétences",
    image:
      "https://www.aliasconsult.be/usr/files/di/fi/5/Gestion-des-competences.jpg",


  } ,
  {
    id: 4,
    name: "Management de projets ",
    image:
      " https://www.iae-bordeaux.fr/application/files/1915/9403/8208/Article_Master_Entrepreneuriat_960x540.jpg",


  }  ,
  {
    id: 5,
    name: "Gestion administratif ",
    image:
      "https://f.hellowork.com/obs-static-images/seo/ObsJob/agent-de-gestion-administrative.jpg",


  } ,
  {
    id: 6,
    name: "Commercial et marketing ",
    image:
      "https://www.bordbia.ie/globalassets/bordbia.ie/about/man-in-a-business-suit-on-a-laptop",


  } 
  ,
  {
    id: 7,
    name: "Informatique",
    image:
      "https://www.mastercours.fr/images/pack-office.jpg",


  } 
  ,
  {
    id: 8,
    name: "Langues ",
    image:
      "https://www.alltradis.com/wp-content/uploads/2018/04/top-10-des-langues-les-plus-difficiles-a-apprendre.png",


  } 
  ,
  {
    id: 9,
    name: "Gestion et finances ",
    image:
      "http://lycee-paul-cornu.fr/stmg-gestion-finance00.jpg?v=3mdv88r2m4ky2u",


  } 
  ,
  {
    id: 10,
    name: "Qualité",
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhVSJWsT-hiZZ0ZDjFXGf8JgR3WRKANsh-douWnHTYZhbXyBZcEHPHff2xWq8K9CRUMbs&usqp=CAU",


  } 
  ,
  {
    id: 11,
    name: "Lean Manufacturing-Lean Management",
    image:
      "https://www.sneci.com/wp-content/uploads/SNECI-ARTICLE-BLOG-LEAN-MANAGEMENT-VS-MANUFACTURING-1-676x354.jpg",


  } 
  ,
  {
    id: 12,
    name: "Metrologie",
    image:
      "https://industrie.felix.fr/wp-content/uploads/sites/2/2021/09/la-metrologie-felix-informatique.jpg",


  } 
  ,
  {
    id: 13,
    name: "Soudure",
    image:
      "https://www.espace-emeraude.com/media/wysiwyg/pages/choisir-materiel-soudure.jpg",


  } 
];
export default data;