import "./services.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Product from "./Product";

import { productData, responsive } from "./data";

import First from "../Presentation/First";

import Society from "../society/Society";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Lastcontact from "../Lastcontact/Lastcontact";
import ANIMATION from "../ANIMATION/ANIMATION";
import MYCARD from "../MYCARD/MYCARD";
import Mytemoignage from "../MYTEMOIGNAGE/Mytemoignage";
import Valsoc from "../Valeurssoc/Valsoc";

<link rel="stylesheet" href="../CSS/all.min.css"/>
export default function Services() {


  // Fonction pour vérifier si un élément est visible à l'écran
function isElementVisible(element) {
  var rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Fonction de gestion de l'événement de défilement
function handleScroll() {
  var image = document.querySelector('.lineUp');
  
  if (isElementVisible(image)) {
    image.classList.add('lineUp');
  }
}

// Ajouter un gestionnaire d'événement de défilement
window.addEventListener('scroll', handleScroll);

  const product = productData.map((item) => (
    <Product
      name={item.name}
      url={item.imageurl}

    />
  ));



  return (
    <div className="ser">
 
<First/>





<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head2 col-sm-12"> 
        <h4 className="lineUp"><span >Nos </span> SERVICES</h4>
        <hr class="star-primary"/>

</div></div>   </div> 
      <Carousel  showDots={true} responsive={responsive} >
        {product}
      </Carousel>
    <Link to="/nosservices" class="bubbly-button1" >Plus de détails   <FontAwesomeIcon icon={faArrowRightLong}  /></Link>
   



<ANIMATION/>




<MYCARD/>






<Valsoc/>
      <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Nos </span> CLIENTS</h4>
        <hr class="star-primary"/>

</div></div>   </div> 
<Society/>
{
/*
<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Avis des </span> CLIENTS</h4>
        <hr class="star-primary"/>

</div></div>   </div> 

<Mytemoignage/> */}
<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 ><span className='firstpart'>Contactez </span> Nous</h4>
        <hr class="star-primary"/>

</div></div>   </div> 
<Lastcontact/>
    </div>
  );
}
