export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1024 },
    items: 5,
    slidesToSlide: 2,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const productData = [
  {
    id: 1,
    imageurl:
    "https://www.matignonprivateconseil.com/wp-content/uploads/2022/09/legal-law-2021-08-31-11-01-50-utc.jpg",
  name: "Veille légale et réglementaire ",
  price: "$21.99",
  description: "Some text about the product..",
  },
  {
    id: 2,
    imageurl:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrSMHRUS3Y03FtjSxAB4x7oyRovnWtEvwS3A&usqp=CAU",
    name: "Audit",
    price: "$99.99",
    description: "Some text about the product..",
  },
  {
    id: 3,
    imageurl:
    "https://www.cci.nc/sites/default/files/styles/full_actu/public/pages/HSE.PNG?itok=cM5LBmlO",
    name: " Étude et assistance en matière de QHSE",
    price: "$99.99",
    description: "Some text about the product..",
  },
  {
    id: 4,
    imageurl:
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuQFYcYcF_t_HS7iyFZPKU0rYYdPS75_aJ04Lq54q1ujRFyPkHiEmHXrQOyw7qVINIEkU&usqp=CAU",
    name: "Formation ",
    price: "$38.99",
    description: "Some text about the product..",
  },
  {
    id: 5,
    imageurl:
    "https://www.sgs.com/-/media/sgscorp/images/industries-and-environment/engineer-working-on-laptop-in-a-factory.cdn.fr-CA.1.jpg",
    name: "Mise en place de systèmes de gestion ",
    price: "$38.99",
    description: "Some text about the product..",
  },
  {
    id: 6,
    imageurl:
    "https://cdn11.bigcommerce.com/s-g95xg0y1db/product_images/uploaded_images/rolled-blueprints.jpg",
    name: "Plans ",
    price: "$149.99",
    description: "Some text about the product..",
  }
];
