import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formadmin() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://ifsm.ci/asset_1/storage/image/faculty/faculty-20.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation des assistantes de direction.
</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.cdg28.fr/images/formation-secretaire-mairie.jpg'></img>
  </div>
  <p  className='myparagtime' >Perfectionnement de secrétaires.
</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS7f37kMxvGajWBZDcop4ufiFWcVyu_Sslf7pnJ4h0OgSubj5s--1tJCvc_grMfCH6NIP8&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Communiquer verbale et non verbale.
</p>
</div>

</div>   


  


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn.filestackcontent.com/eQ5eU3ZZQ3a0xYiUa0Nx'></img>
  </div>
  <p  className='myparagtime' >Améliorer ses écrits professionnels.
</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.metalemploi.org/wp-content/uploads/2020/06/archive.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation à la documentation et archives.
</p>
</div>

</div>   

<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTmn1Lo_NJ12dH0EbkLhJxl4C5_245MElxgZ5bLAM01qbzFRjSCemx9FpVRGlcEB-ibecE&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Formation en bureautique.</p>
</div>

</div> 

  </div>
</section>
<Bizzard/>

</div>
  )}