import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import marwen from '../Newsliders/marouenimg.png';
import since from '../ANIMATION/SINCE.png'
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './aboutus.Less'
import integ from './integ.png'
import inn from './inn.png'
import excellent from './excellent.png'
import innovation from './innovation.png'
import Quote from './Quote';
import Value from './Value'
import Bizzard from '../MYFOOTER/Bizzard';
import Team from '../Team/Team';
import ANIMATION from '../ANIMATION/ANIMATION';
import MYCARD from "../MYCARD/MYCARD"
gsap.registerPlugin(ScrollTrigger);

export default function Aboutus ()  {




  const imageRef = useRef(null);
  const mytexto = useRef(null);
  const textRefsoc = useRef(null);
  const imageRefsoc = useRef(null);
  useEffect(() => {
    const el = imageRef.current;
    const el1 = mytexto.current;
    const eltext = textRefsoc.current;
    const elimg = imageRefsoc.current;
    gsap.fromTo(
      [el, el1 ,eltext,elimg],
      { y: '100%', opacity: '0' },
      {
        y: '0%',
        opacity: '100%',
        duration: 6,
        scrollTrigger: {
          trigger: '.section-head1', // Déclencheur du scroll
          toggleActions: 'restart none none none',
        },
      }
    );
  }, []);
return (
<div>

<div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12">
        <h4 className="lineUp" ><span >Notre </span> HISTOIRE?</h4>
        <hr class="star-primary"/>
        
        </div>

 </div>
    </div>




    <div className="row">

  

<div className="animsociete" ref={textRefsoc} >
<p>
La société Salima de gestion des risques professionnels est un bureau d’étude, de consulting et de formation.
Nous accompagnons nos clients à leurs mise en confirmité réglementaire en matière de qualité , santé ,sécurité au travail
 , environnement et social et nous facilitons l’intégration d’une culture HSE au sein des organismes
 tout en faisant gagner du temps, de l'énergie tout en optimisant les budgets.
</p>
</div>
<img ref={imageRefsoc} src={since} alt="" className="logoanimsoci" />
</div>

      <div className="row">

  


        <div ref={mytexto} className="mytexto">
        <p>Chez Salima, nous sommes passionnés par le fait d'aider nos clients
à réussir. Notre parcours a commencé en 2020, lorsque le fondateur
M. Marouen Daoud a vu le besoin de créer un bureau de conseil et de
formation qui fournissait des solutions innovantes et un service
exceptionnel aux clients de diffrérents secteurs.
<br/>
Depuis, nous avons étendu nos services par:
<ol >  <li>
 la mise en place d'une plateforme de veille légale et réglementaire en matiére hygéne ,sécurité ,sureté,
environnement, qualité ,sociale et socitale . </li>

 <li>

l'assistance des organismes à la mise en place des systémes de managment 
de la qualité, de la securité SST et SMS ,enviromental ,
</li>
<li>
 l'étude et à l'assistance en matière de
QHSE, de formation et de systèmes de gestion. Nous travaillons avec
des clients tant au niveau national qu'international et notre équipe
d'experts se consacre à les aider à atteindre leurs objectifs.</li> </ol>
</p>
        </div>
        <img ref={imageRef} src={marwen} alt="" className="logoapp" />
      </div>





<MYCARD/>



    <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 className="lineUp" ><span >Nos </span> VALEURS?</h4>
        <hr class="star-primary"/>
          <p >Chez Salima, nous sommes guidés par nos valeurs fondamentales :
l'excellence, l'innovation et l'intégrité.  <br/>Nous croyons qu'il faut offrir un
service exceptionnel et des solutions novatrices à nos clients, tout en
respectant les normes éthiques les plus élevées.

</p>
</div>
       
       </div>
       </div>



<div class="flexbox">
        <div class="flexcard flexcardGreen">
            <div class="flexcardNumber flexcardNumberGreen">01</div>
            <div class="flex flexcardTitle">EXCELLENCE
</div>
            <div class="flex flexcardText"> Notre équipe de consultants et de formateurs
possède des années d'expérience et des connaissances
approfondies dans des domaines tels que la conformité légale
et réglementaire, la gestion des risques et l'HSE.
 </div>
            <div class="flex flexcardImg"><img class="flexcardimgItem"
                    src={excellent} alt=""/></div>
        </div>
        <div class="flexcard flexcardBlue">
            <div class="flexcardNumber flexcardNumberBlue">02</div>
            <div class="flex flexcardTitle"> INNOVATION</div>



            <div class="flex flexcardText">Nous nous engageons à fournir des résultats
qui dépassent les attentes, et nous avons fait nos preuves en
aidant les entreprises à améliorer leurs pratiques et à atteindre
leurs objectifs.
</div>
            <div class="flex flexcardImg"><img class="flexcardimgItem"
                    src={inn} alt=""/></div>
        </div>
        <div class="flexcard flexcardOrange">
            <div class="flexcardNumber flexcardNumberOrange">03</div>
            <div class="flex flexcardTitle">INTÉGRITÉ</div>
            <div class="flex flexcardText">Nous sommes toujours à la recherche de
solutions nouvelles et innovantes pour aider nos clients à
réussir, et nous nous tenons au courant des dernières
tendances et des meilleures pratiques du secteur.</div>
            <div class="flex flexcardImg">
                <img class="flexcardimgItem"
                    src={integ} alt=""/></div>
      
    </div>
    </div>



    <div class="feat bg-gray pt-5 pb-5">
      <div class="row">
        <div class="section-head col-sm-12"> 
        <h4 className="lineUp"><span >Notre </span> Equipe</h4>
        <hr class="star-primary"/>
</div>
</div>
</div>
    <Team/>
<Bizzard/>
    </div>
) }
