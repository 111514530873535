import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formasodure() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.decideurs-magazine.com/images/01-MAGAZINE-DECIDEURS/01-ARTICLES/old/60b4e2a9a5349.jpg'></img>
  </div>
  <p  className='myparagtime' >Les fondamentaux de la métrologie.

</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.espace-emeraude.com/media/wysiwyg/pages/Soudure-MMA.jpg'></img>
  </div>
  <p  className='myparagtime' >Technologie et mise en œuvre du procédé de soudage semi-automatique MIG/MAG.

</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.sef-formation.info/wp-content/uploads/sites/30/2017/02/tig-1.jpg'></img>
  </div>
  <p  className='myparagtime' >Technologie et mise en œuvre du procédé de soudage TIG.

</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.iri-lyon.com/mediaCache/4/8/3/8/6b4bfd0138435d2784a3c9032679e6a3bec6537a.jpeg'></img>
  </div>
  <p  className='myparagtime' >Technologie et mise en œuvre du procédé de soudage oxyacétylénique.

</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.minutefacile.com/wp-content/uploads/2021/05/soudure_arc.jpg'></img>
  </div>
  <p  className='myparagtime' > Technologie et mise en œuvre du procédé de soudage à l’arc avec électrode enrobée.

    </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.sef-formation.info/wp-content/uploads/sites/30/2020/12/Formation-soudage-MIG-MAG-1024x768.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage semi-automatique MIG/MAG.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.digits-pro.fr/img/cms/miiig.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage semi-automatique MIG sur acier au carbone.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn.manomano.com/media/edison/0/1/5/8/015892e9af8c.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage TIG sur acier faiblement allié.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cdn.futura-sciences.com/buildsv6/images/largeoriginal/6/5/b/65b5d0981a_50170729_sp-choisir-poste-tig-1.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage TIG sur acier inoxydable.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.formation-industries-paca.fr/media/welding-1209208_1920-1024x683.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage TIG sur aluminium.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.tra-c.com/wp-content/uploads/2021/01/certifISO9001.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage TIG sur cuivre et alliages.

</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://i.ytimg.com/vi/RoJ18Py6Y2o/maxresdefault.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage TIG sur magnésium et alliages.

</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.formation-soudure.com/UPLOADS/programme-formation-chalumeau-flamme-oa.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage oxyacétylénique.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.sef-formation.info/wp-content/uploads/sites/30/2020/12/ARC-avec-%C3%A9lectrodes-enrob%C3%A9es.jpg'></img>
  </div>
  <p  className='myparagtime' >Formation pratique en soudage à l’arc avec électrode enrobée.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.iel.ca/wp-content/uploads/2018/02/Soudeur-1030x685.jpg'></img>
  </div>
  <p  className='myparagtime' >Soudabilité métallurgique et opératoire des aciers inoxydables.
</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://fer-metal-concept.com/wp-content/uploads/2022/01/tube-acier-rectangle-1280x720.jpg'></img>
  </div>
  <p  className='myparagtime' >Classification et désignation normalisée des matériaux.

</p>
</div>

</div>   




<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://anais-fournituresindustrielles.com/media/images/equipement/1.jpg'></img>
  </div>
  <p  className='myparagtime' >Hygiène et sécurité en soudage.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://itc-soudage.fr/wp-content/uploads/2023/04/Screenshot-2023-04-26-at-10-46-24-norme-symbole-soudure-%E2%80%93-Recherche-Google.png'></img>
  </div>
  <p  className='myparagtime' >Rédigez vos descriptifs de mode opératoire de soudage (DMOS).

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://metalblog.ctif.com/wp-content/uploads/sites/3/2019/07/Soudage-MIG-des-alliages-aluminium.jpg'></img>
  </div>
  <p  className='myparagtime' >Technologie des procédés usuels de soudage à l’arc.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://static6.depositphotos.com/1003587/630/i/600/depositphotos_6300824-stock-photo-welder.jpg'></img>
  </div>
  <p  className='myparagtime' >Perfectionnement en brasage et soudobrasage.

</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://dgm-industrie.fr/wp-content/uploads/2020/01/Controle-Ultrasons-Phased-Array03.jpeg'></img>
  </div>
  <p  className='myparagtime' >Les techniques de soudage des pipelines sur site et le contrôle non destructif.
</p>
</div>

</div>   



  </div>
</section>
<Bizzard/>

</div>
  )}