import React from "react";
import { Link } from "react-router-dom";

export default function Product(props) {
  const { activeItem } = props;

  return (
  <div className="SERVICES">
          <Link to="/nosservices">

    <div className="cardservicee">
      
      <img className="product--image11" src={props.url}
      />  
      <div class="contentservice">
      </div>
      <h1 className={`nameh1 ${activeItem === "nosservices" ? "active" : ""}`}>
            {props.name}
          </h1>
    </div>
</Link>
    </div>
  
  );
}
