import React, { useEffect } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './first.css';
import work from './assets/work.png';
import shield from './assets/shield.png';
import businessman from './assets/businessman.png';
import managment from './assets/management.png';

const First = () => {
  useEffect(() => {
    const startCountAnimation = () => {
      const valueDisplays = document.querySelectorAll('.num');
      const interval = 4000;
      valueDisplays.forEach((valueDisplay) => {
        let startValue = 0;
        const endValue = parseInt(valueDisplay.getAttribute('data-val'));
        const duration = Math.floor(interval / endValue);
        const counter = setInterval(() => {
          startValue += 1;
          valueDisplay.textContent = startValue;
          if (startValue === endValue) {
            clearInterval(counter);
          }
        }, duration);
      });
    };

    startCountAnimation();
  }, []);

  const images = [
    "https://fnh.ma//uploads/actualites/5eccf496b083e.jpeg",
    "https://www.mensura.be/uploads/cache/hero/uploads/media/624ae3371a6b5/brandveiligheid-kleine-blusmiddelen.png?202303071623",
    "https://www.actu-environnement.com/images/illustrations/news/21249_une.jpg",
    "https://cdn-s-www.bienpublic.com/images/00530E0F-0E6F-498B-9B24-AF870F6EE17D/NW_raw/les-equipements-de-protection-individuelle-permettent-de-proteger-la-personne-et-eviter-qu-elle-ne-soit-directement-exposee-aux-risques-lies-a-son-metier-photo-adobe-stock-1641475298.jpg",
    "https://www.vdp.com/resources/resized/1200x630/498/852.jpg",
  ];
    return (
      <div> 
        <Slide>

          <div className='allslide'>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]})` }}>
               
                <p class="titreimages"> Réduire la  consommation d'énergie<br/>dans votre industrie</p>
            


              {/*  <div class="rowfirst">
  <div class="columnfirst">
    <div class="cardfirst">
     <img className="managmentfirst" src={managment} alt="product image" />
       <span className="num"  data-val="50">00</span>

       <p >Audit réalisées</p>
    </div>
  </div>

  <div class="columnfirst">
    <div class="cardfirst">
    <img className="managmentfirst" src={managment} alt="product image" />
    <span className="num"  data-val="50">00</span>
    <p>Système de management certifiés</p>
    </div>
  </div>
  
  <div class="columnfirst">
    <div class="cardfirst">
    <img className="managmentfirst" src={managment} alt="product image" />
    <span className="num"  data-val="50">00</span>
    <p>Etudes de sécurité incendie</p>
    </div>
  </div>
  
  <div class="columnfirst">
    <div class="cardfirst">
    <img className="managmentfirst" src={managment} alt="product image" />
    <span className="num"  data-val="50">00</span>
    <p > Formations réalisées</p>
    </div>
  </div>
</div>










                
    <div className="container">
     <i ></i>
     <img className="managment" src={managment} alt="product image" />
     <span className="num"  data-val="50">00</span>

 <span class="textBLUR">Audit<br/>réalisées</span>

     </div>
     <div className="container">
     <i ></i>
     <img className="businessman" src={businessman} alt="product image" 
      /> 
       <span className="num"  data-val="50">00</span>

 <span class="textBLUR">système de management certifiés</span>

     </div>
     <div className="container">
     <i ></i>
     <img className="shield" src={shield} alt="product image" 
      />  
       <span className="num"  data-val="50">00</span>

 <span class="textBLUR">Etudes de sécurité incendie</span>

     </div>
     <div className="container">
     <i ></i>
     <img className="work" src={work} alt="product image" 
      />  
       <span className="num"  data-val="50">00</span>

 <span class="textBLUR"> Formations réalisées</span>

     </div>*/
}
                </div>

            </div>
            </div>







            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
              
    <p className='titreimages'>Prévenir les risques d'incendie</p>
   
  
     </div>
       

          




            </div>



            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
               
                <p className='titreimages'>Préserver l'environnement</p>
              
            </div>
</div>

            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                 
                  
           
                    <p class="titreimages"> Choisissez votre Equipement de   <br/>protection individuelle et collective</p>
                   
                    <div class="rowfirst">
 

  
  
  
  
 
</div>



                   
                 
    
    
    
     </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                <p class="titreimages"> Améliorer votre sécurité   </p>

               






                
    
    
       
    
                </div>
            </div>
            
        </Slide>
    </div>
    );
};

export default First;