import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formrh() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.formation-ressources-humaines.com/wp-content/uploads/2021/04/Ressources-humaines-5.jpg'></img>
  </div>
  <p  className='myparagtime' >Les fondamentaux de la Gestion des Ressources Humaines.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.celge.fr/wp-content/uploads/2021/08/team-4864035_960_720.jpg'></img>
  </div>
  <p  className='myparagtime' >Les outils de Pilotage de la fonction ressources humaines.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://static-blog.eurecia.com/sites/default/files/inline-images/tableau-de-bord-RH-excel.png'></img>
  </div>
  <p  className='myparagtime' >Le Tableau de Bord des ressources humaines.</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://us.123rf.com/450wm/pwsr01/pwsr012112/pwsr01211200013/178947724-concept-d-ic%C3%B4ne-esg-dans-la-main-de-la-femme-pour-l-environnement-le-social-et-la-gouvernance-en.jpg?ver=6'></img>
  </div>
  <p  className='myparagtime' >Développement durable et ressources humaines.</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://cms.weka.ch/fileadmin_personal_schweiz/USERDATA/Avenir_des_RH_neu.jpg'></img>
  </div>
  <p  className='myparagtime' >   La démarche qualité en ressources humaines.      </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://monportailrh.com/wp-content/uploads/2018/06/monportailrh-logiciel-gestion-talents-sirh.png'></img>
  </div>
  <p  className='myparagtime' >La gestion des compétences : outils et méthodes.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://recrutement.pro/wp-content/uploads/2021/04/pitinan190804022.jpg'></img>
  </div>
  <p  className='myparagtime' >La gestion prévisionnelle des emplois et des compétences « GPEC ».</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://1.bp.blogspot.com/-maHN7g1nsl8/YXWPi6qT1jI/AAAAAAAACIs/koHf6Y_ktggbw32zVClNrZPTImisW_DXQCLcBGAsYHQ/w1200-h630-p-k-no-nu/htehthy.jpg'></img>
  </div>
  <p  className='myparagtime' >Manager les compétences collectives.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.usinenouvelle.com/mediatheque/5/9/6/000759695_896x598_c.jpg'></img>
  </div>
  <p  className='myparagtime' >Les techniques de recrutement.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://global-uploads.webflow.com/638b3d0a5fd2fda2068f23dc/63da6fb49e0ef0db13e6dde2_politique-salariale.jpg'></img>
  </div>
  <p  className='myparagtime' >Politique et stratégie de rémunération.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.usinenouvelle.com/mediatheque/5/9/6/000759695_896x598_c.jpg'></img>
  </div>
  <p  className='myparagtime' >Les techniques de recrutement.</p>
</div>

</div>   





  </div>
</section>
<Bizzard/>

</div>
  )}