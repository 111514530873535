import React from "react";
import "./team.css";
import { Link } from "react-router-dom";

export default function Profiles(props) {

  return (
    <div class="slide-containerpro swiper">
    <div class="slide-contentpro">
        <div class="cardpro-wrapper ">
            <div class="cardpro swiper-slidepro">
                <div class="image-contentpro">
                    <span class="overlaypro"></span>

                    <div class="cardpro-imagepro">
                        <img  src={props.url} alt="" class="cardpro-imgpro"/>
                    </div>
                </div>

                <div class="cardpro-contentpro">
                    <p class="namepro">{props.name}</p>
                    <p class="position">{props.position}</p>
                  <p class="descriptionpro">{props.description} </p>

                </div>
                <p class="contact">{props.contact} </p>

            </div>
       </div>
       </div>
       </div>

  );
}
