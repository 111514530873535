import React from 'react'
import $ from 'jquery';
import ScrollReveal from 'scrollreveal';
import Bizzard from '../MYFOOTER/Bizzard';
import timeimg from "../Realwork/mes-formations/f1.jpeg"

let sr;
$(function(){

    let sr;
    sr = ScrollReveal();
  
    if ($(window).width() < 768) {
  
        if ($('.timeline-content').hasClass('js--fadeInLeft')) {
            $('.timeline-content').removeClass('js--fadeInLeft').addClass('js--fadeInRight');
        }
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    } else {
        
        sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
    }
    
    sr.reveal('.js--fadeInLeft', {
          origin: 'left',
          distance: '300px',
            easing: 'ease-in-out',
          duration: 800,
        });
  
        sr.reveal('.js--fadeInRight', {
          origin: 'right',
          distance: '300px',
          easing: 'ease-in-out',
          duration: 800,
        });
  
});


export default function Formmeetrologie() {
  return (
    <div className='mybodytime'>


<section class="timeline">
  <div class="containertime">




  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.legarrec.com/wp-content/uploads/2022/05/Image-metrologie-scaled.jpeg'></img>
  </div>
  <p  className='myparagtime' >Les fondamentaux de la métrologie.</p>
</div>

</div>   





<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPfeYqppA03XQlS15_jZz27l3_-iSZdoIcwnaOKBHDS94oCHV3lASmaexlLFD_t0OK67U&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Mise en place de la norme ISO/CEI 17025.</p>
</div>

</div>   







<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.lcie.fr/wp-content/uploads/2021/04/iso_iec17025-1568x878.png'></img>
  </div>
  <p  className='myparagtime' >Audit interne suivant le référentiel ISO/CEI 17025.
</p>
</div>

</div>   


  
<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz7YrNA1mepjRT-CI7VrafcNgbdEPaKU4RVJGSWMmOMx9BpNMbcwZbrezyTGpINCLNFaM&usqp=CAU'></img>
  </div>
  <p  className='myparagtime' >Maîtrise de l’étalonnage dimensionnel en milieu industriel en milieu industriel et exploitation des instruments de mesure.
</p>
</div>

</div>   






<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.controles-essais-mesures.fr/wp-content/uploads/2019/07/DMM-images-1486048248.jpg'></img>
  </div>
  <p  className='myparagtime' >   Métrologie en laboratoire d’analyses et d’essais.
     </p>
</div>

</div>   


    
  <div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://t3.ftcdn.net/jpg/05/31/34/84/360_F_531348440_wYDohF1l8L7LegWEDLsM6CLmRIsvnMrc.jpg'></img>
  </div>
  <p  className='myparagtime' >Amélioration continue de votre système qualité de laboratoire selon l’ISO/CEI 17025.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.cirad.fr/var/cirad/storage/images/_aliases/hero/1/6/5/8/28561-2-fre-FR/_RCA9283.jpg'></img>
  </div>
  <p  className='myparagtime' >Audit qualité de laboratoire selon la norme ISO/CEI 17025.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.experligence.com/wp-content/uploads/2019/08/image-122.png'></img>
  </div>
  <p  className='myparagtime' >Correspondant métrologie : améliorer le système de management de la mesure.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://plastiform.b-cdn.net/wp-content/uploads/2020/07/rugosim%C3%A8tre-avec-patin-plastiform.jpg'></img>
  </div>
  <p  className='myparagtime' >Déterminer l’aptitude de vos moyens de mesure.
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.smart-metrology.com/wp-content/uploads/2016/03/Image-Article-CD-1-300x225.png'></img>
  </div>
  <p  className='myparagtime' >Evaluation de la fonction métrologie : améliorez la performance du Système de Management de la Mesure (SMM).
</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.gometrics.net/wp-content/uploads/elementor/thumbs/Calibracion-Laboratorio-Presion-1-p1pkf9t29gconp4ve2wy87k3c04ugbduh2p5i6o2dc.jpg'></img>
  </div>
  <p  className='myparagtime' >Interpréter vos incertitudes de mesure ou d’essais.</p>
</div>

</div>   



<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.alpiassociazione.it/wp-content/uploads/2016/12/ISO-17065-4-1.jpg'></img>
  </div>
  <p  className='myparagtime' >ISO/CEI 17025 mettre en œuvre les exigences de la norme.
</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://myomniscient.com/wp-content/uploads/2021/02/domaine_pilotage_decompte.png'></img>
  </div>
  <p  className='myparagtime' >Optimiser la gestion d’un parc d’équipements de mesure.
</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://www.cetiat.fr/data/medias/37/style/home_paysage/labo-thermo.jpg'></img>
  </div>
  <p  className='myparagtime' >Qualité des essais, des analyses et des étalonnages : mettez en œuvre et optimisez les outils de surveillance des résultats.
</p>
</div>

</div>   


<div class="timeline-item">

<div class="timeline-img">
</div>

<div class="timeline-content timeline-card js--fadeInRight">
<div >
    <img src='https://img.over-blog-kiwi.com/2/49/57/72/20171130/ob_0baedb_industrie-stats.jpg'></img>
  </div>
  <p  className='myparagtime' >Statistiques de base appliquées à la mesure.</p>
</div>

</div>   






  </div>
</section>
<Bizzard/>

</div>
  )}