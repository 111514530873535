import React  from "react";
import { Link } from "react-router-dom";
import Services from "../pages/Services/Services";
import "../CardsFormations/formation.css";
const Card= ({data}) => {
    return (
    <>
    {data.map((card, index) => (
        <div className='productList'>
        <div key={index}  className='productCard' >
           
        <img src={card.image} alt='product-img' className='productImage'></img>
        
      
      
   
    <button class="btnb">
    <Link to={`/Formations/${card.name}`} class="text-reset  text-decoration-none">Plus de détails</Link>
    </button>

       

<div className='productCard__content'>
              <h3 className='productName'>{card.name}</h3>
         
           
          </div>
      </div>
         </div>
     
    ))}

    </>
    )
}
export default Card;